'use client'

import {
  forwardRef,
  useEffect,
  useRef,
  type ComponentPropsWithoutRef,
  type ComponentRef,
} from 'react'
import type { ApiType } from '~/api/types'
import { cx } from '~/shared/lib/cva.config'
import { mergeRefs } from '~/shared/lib/merge-refs'

type Props = ComponentPropsWithoutRef<'div'> & {
  widgetSource: string
  widgetTelegramLogin: string
  widgetSize: string
  widgetRequestAccess: string
  widgetOnAuth: ((payload: ApiType<'TelegramData'>) => void) | string
  widgetAsync: boolean
  widgetRadius?: number
  widgetUserPic?: boolean
}

declare global {
  interface Window {
    TelegramLoginWidget: object
  }
}

export const TelegramLoginWidget = forwardRef<ComponentRef<'div'>, Props>(
  (
    {
      className,
      widgetSource,
      widgetTelegramLogin,
      widgetSize,
      widgetRequestAccess,
      widgetAsync,
      widgetOnAuth,
      widgetRadius,
      widgetUserPic,
      ...props
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const script = document.createElement('script')
      script.src = widgetSource
      script.setAttribute('data-telegram-login', widgetTelegramLogin)
      script.setAttribute('data-size', widgetSize)
      script.setAttribute('data-request-access', widgetRequestAccess)

      if (typeof widgetRadius === 'number') {
        script.setAttribute('data-radius', widgetRadius.toString())
      }

      if (widgetUserPic === false) {
        script.setAttribute('data-userpic', 'false')
      }

      script.async = widgetAsync

      if (typeof widgetOnAuth === 'function') {
        window.TelegramLoginWidget = {
          onAuth: widgetOnAuth,
        }
        script.setAttribute('data-onauth', 'TelegramLoginWidget.onAuth(user)')
      } else {
        script.setAttribute('data-auth-url', widgetOnAuth)
      }

      containerRef.current?.appendChild(script)
    }, [
      widgetOnAuth,
      widgetRequestAccess,
      widgetSize,
      widgetSource,
      widgetTelegramLogin,
      widgetAsync,
      widgetRadius,
      widgetUserPic,
    ])

    return (
      <div
        ref={mergeRefs([containerRef, ref])}
        className={cx('', className)}
        {...props}
      />
    )
  },
)

TelegramLoginWidget.displayName = 'TelegramLoginWidget'
